import React, { Dispatch, useEffect, useState } from 'react'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import { FormikStepper } from '../../../components/form/wizzard/FormikStepper'
import {
  AdGroupCreateInput,
  AdFormat,
  ChannelType,
  ContentStrategy,
  CostType,
  Network,
  useCreateAdgroupMutation,
  BudgetCapping as BudgetCappingEnum,
  AdGroupPartsFragment,
  SidebarItemsDocument,
  CompanyType,
  usePriorityListLazyQuery,
  DailyBudgetType,
} from '../../../__generated__/graphql'
import { FormikValues } from 'formik'
import { useNavigation } from '../../../utils/hooks/useNavigation'
import { useCompanyDetailsContext } from '../../../utils/hooks/useCompanyDetailsContext'
import prepareFormValues from '../../../utils/prepareFormValues'
import AdgroupDetailStep, { detailsSchema } from './AdgroupDetailStep'
import AdgroupBudgetStep, { budgetAndDateSchema } from './AdgroupBudgetStep'
import { useCampaignIdFinder } from '../../../utils/hooks/campaign/useCampaignIdFinder'
import { Edit } from '@mui/icons-material'
import { useUserPermissions } from '../../company/currentCompanyDisplayedContext'
import { sanitizeUrl } from '../../../utils/urlUtils'
import Loader from '../../../components/loader/Loader'
import { isNil } from 'lodash-es'
interface AdgroupCustomFields {
  channels: ChannelType[]
}

type AdgroupCreationSchema = AdGroupCreateInput & AdgroupCustomFields

const CreateAdgroup: React.FC<{
  setOpen: Dispatch<React.SetStateAction<boolean>>
  campaignId?: string | number
}> = ({ setOpen, campaignId }) => {
  const { adgroupPriority } = useUserPermissions()
  const t = useAppTranslations()
  const {
    company: { slug, id, type },
  } = useCompanyDetailsContext()
  const [error, setError] = useState(undefined)
  const [createAdgroup] = useCreateAdgroupMutation({
    refetchQueries: () => [
      {
        query: SidebarItemsDocument,
        variables: {
          companyId: id,
          archived: false,
          isAdvertiser: type === CompanyType.Advertiser,
          isTradingDesk: type === CompanyType.TradingDesk,
          isPublisher: type === CompanyType.Publisher,
        },
      },
    ],
    awaitRefetchQueries: true,
  })
  const [createdAdgroup, setCreatedAdgroup] = useState<AdGroupPartsFragment | null>()
  const [getPriorityList, { data: maybePriorityList, loading: priorityListLoader }] = usePriorityListLazyQuery()

  useEffect(() => {
    if (!isNil(campaignId) && adgroupPriority)
      getPriorityList({
        variables: { campaignId: campaignId },
      })
  }, [campaignId, adgroupPriority])

  const navigation = useNavigation()

  if (adgroupPriority && (isNil(maybePriorityList) || priorityListLoader)) return <Loader />

  const baseAdgroup: AdGroupCreateInput = {
    campaignId: campaignId || '',
    name: '',
    isActive: false,
    network: Network.Media,
    channelWeb: false,
    channelEmail: true,
    format: AdFormat.Native,
    costType: CostType.Cpc,
    costValue: 0,
    contentStrategy: ContentStrategy.Promotional,
    redirectUrl: '',
    dailyBudget: 15,
    dailyBudgetType: DailyBudgetType.Fixed,
    budgetCapping: 0,
    budgetCappingType: BudgetCappingEnum.Overall,
    monthlyBudget: undefined,
    overallBudget: undefined,
    startDate: '',
    endDate: '',
    priorityId: (maybePriorityList?.campaign.advertiser.reseller.priorities ?? []).find((p) => p.isDefault)?.id,
  }

  const initialValues: AdgroupCreationSchema = {
    ...baseAdgroup,
    channels: [],
  }

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    const cleanData = prepareFormValues(values)

    const updatedDailyBudget =
      cleanData.dailyBudgetType === DailyBudgetType.Fixed && cleanData.dailyBudget > 0 ? cleanData.dailyBudget : null
    const updatedDailyBudgetType = updatedDailyBudget === null ? DailyBudgetType.Open : DailyBudgetType.Fixed

    const updatedBudgetCapping =
      cleanData.BudgetCappingType == BudgetCappingEnum.Open ||
      cleanData.budgetCapping === 0 ||
      isNil(cleanData.budgetCapping)
        ? null
        : cleanData.budgetCapping

    const toCreate: AdGroupCreateInput = {
      campaignId: cleanData.campaignId,
      channelEmail: cleanData.channelEmail,
      channelWeb: cleanData.channelWeb,
      contentStrategy: cleanData.contentStrategy,
      endDate: cleanData.endDate,
      format: cleanData.format,
      isActive: cleanData.isActive,
      name: cleanData.name,
      network: cleanData.network,
      priorityId: cleanData.priorityId,
      redirectUrl: sanitizeUrl(cleanData.redirectUrl),
      startDate: cleanData.startDate,

      costType: cleanData.costType,
      costValue: cleanData.costValue,
      dailyBudget: updatedDailyBudget,
      dailyBudgetType: updatedDailyBudgetType,
      budgetCapping: updatedBudgetCapping,
      budgetCappingType: cleanData.budgetCappingType,
      monthlyBudget: undefined,
      overallBudget: undefined,
    }

    await createAdgroup({
      variables: {
        adgroup: toCreate,
      },
    })
      .then((res) => {
        const createdAdgroup = res?.data?.createAdGroup
        if (createdAdgroup) setCreatedAdgroup(createdAdgroup)
      })
      .catch((err: React.SetStateAction<undefined>) => setError(err))
  }

  const successButton = {
    label: t('adgroup.goToAdgroup', { adgroupName: createdAdgroup?.name }),
    startIcon: <Edit />,
    onClick: () => {
      if (createdAdgroup) {
        navigation.goToAdgroup(slug, createdAdgroup.id)
        setOpen(false)
      }
    },
  }

  return (
    <FormikStepper
      initialValues={initialValues}
      onSubmit={handleSubmit}
      error={error}
      setError={setError}
      enableReinitialize={true}
      successMessage={t('adgroup.created')}
      successActionButton={successButton}
      setOpen={setOpen}
      mergeSteps
    >
      <AdgroupDetailStep
        validationSchema={detailsSchema}
        label={t('adgroup.details.title')}
        adgroup={baseAdgroup}
        priorityList={maybePriorityList?.campaign.advertiser.reseller.priorities ?? []}
      />
      <AdgroupBudgetStep
        validationSchema={budgetAndDateSchema}
        label={t('adgroup.budgetAndDates.title')}
        adgroup={baseAdgroup}
      />
    </FormikStepper>
  )
}

export const CreateAdGroupInCampaign: React.FC<{ setOpen: Dispatch<React.SetStateAction<boolean>> }> = ({
  setOpen,
}) => {
  const defaultCampaignId = useCampaignIdFinder()

  if (!defaultCampaignId) return <Loader />
  return <CreateAdgroup setOpen={setOpen} campaignId={defaultCampaignId} />
}

export default CreateAdgroup

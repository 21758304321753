import { Grid, MenuItem } from '@mui/material'
import React, { useMemo } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import TransformDisplayToEdition from '../../../../components/form/TransformDisplayToEdition'
import mapEnumForSelect from '../../../../utils/mapEnumForSelect'
import { CostType } from '../../../../__generated__/graphql'
import { displayAsCurrency } from '../../../../utils/displayAsCurrency'
import { FieldsProps } from '.'
import { useUserPermissions } from '../../../company/currentCompanyDisplayedContext'

const Cost: React.FC<FieldsProps> = ({ adgroup, isWizard = false, canEdit = true }): JSX.Element => {
  const { superAdmin, adgroupCpl, adgroupCpm } = useUserPermissions()

  const costTypesOptions = useMemo(() => {
    const costTypes = mapEnumForSelect(CostType)

    return costTypes
      .filter((type) => {
        if (type.value === CostType.Cpl || type.value === CostType.Cpo) return adgroupCpl
        if (type.value === CostType.Cpm) return adgroupCpm
        return true
      })
      .map((type, i) => (
        <MenuItem value={type.value} key={i}>
          {type.label.toUpperCase()}
        </MenuItem>
      ))
  }, [adgroupCpl, adgroupCpm])

  return (
    <Grid container item xs={3} spacing={1}>
      <Grid xs={5} item>
        <TransformDisplayToEdition
          type="number"
          name="costValue"
          required={true}
          label="adgroup.details.costValueWithCurrency"
          text={displayAsCurrency(adgroup.costValue)}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
            inputProps: { step: 0.01, min: 0.05 },
          }}
          disableHoverEdition={isWizard}
          editable={canEdit}
        />
      </Grid>
      <Grid xs={5} item>
        <TransformDisplayToEdition
          type="number"
          inputType="select"
          withNone={false}
          name="costType"
          required={true}
          label="adgroup.details.costType"
          text={adgroup.costType?.toUpperCase()}
          disableHoverEdition={isWizard}
          editable={canEdit && (isWizard || superAdmin)}
        >
          {costTypesOptions}
        </TransformDisplayToEdition>
      </Grid>
    </Grid>
  )
}
export default Cost

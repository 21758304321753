import { Grid } from '@mui/material'
import { FormikValues, useFormikContext } from 'formik'
import React from 'react'
import SectionTitle from '../../../../components/form/SectionTitle'
import { useAdgroupContext } from '../../../../utils/hooks/adGroup/useAdgroupContext'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import {
  AdGroupTargeting,
  Country,
  GeoTargetingInput,
  TargetingCircleInput,
  TargetingZoneInput,
  useCampaignQuery,
} from '../../../../__generated__/graphql'
import GeoTargetingFields, { LocationTypesEnum } from './GeoTargetingFields'
import GeoTargetingMap from '../../../../components/form/GeoTargetingMap'
import { AdgroupExtendedSettingsFormInterface } from '../Settings'
import { EditionModes, useEditionMode } from '../../../../utils/providers/editionModeContext'

export interface AdgroupGeoTargetingTypesFormInterface extends FormikValues {
  geoExclusive: boolean
  cities?: Array<TargetingCircleInput>
  regions: Array<TargetingZoneInput>
  departements: Array<TargetingZoneInput>
  countries: Array<Country>
  type: string
  countryBlacklistRedirectUrl: string
  authorizedCountries: Array<string>
}
export interface GeoTargetingProps {
  adgroupTargeting: AdGroupTargeting
}

export const getLocationType = (geoTargeting: GeoTargetingInput): LocationTypesEnum => {
  const { regions, departements, countries } = geoTargeting
  if (regions?.length) return LocationTypesEnum.Regions
  else if (departements?.length) return LocationTypesEnum.Departements
  else if (countries?.length) return LocationTypesEnum.Countries
  else return LocationTypesEnum.Cities
}

export const GeoTargetingForm: React.FC<GeoTargetingProps> = ({ adgroupTargeting }): JSX.Element => {
  const formik = useFormikContext<AdgroupExtendedSettingsFormInterface>()
  const t = useAppTranslations()
  const [editionModeContext] = useEditionMode()
  const [adgroup] = useAdgroupContext()
  const { data: campaignData } = useCampaignQuery({
    variables: { campaignId: adgroup.campaign.id },
  })
  const countryCode = campaignData?.campaign?.market?.country?.code

  return (
    <>
      <SectionTitle
        title="adgroup.geoTargeting.title"
        secondaryText={t('adgroup.geoTargeting.marketCountry', { countryCode: countryCode })}
        helperText={t('adgroup.geoTargeting.helperText')}
      />
      <Grid container>
        <Grid item xs={12} lg={6}>
          <GeoTargetingFields adgroupTargeting={adgroupTargeting} countryCode={countryCode} />
        </Grid>
        <Grid item xs={6}>
          {countryCode && (
            <GeoTargetingMap
              locations={
                editionModeContext === EditionModes.Edition
                  ? (formik.values.targeting[formik.values.targeting.type as keyof typeof formik.values.targeting] as
                      | Array<TargetingCircleInput>
                      | Array<TargetingZoneInput | string>)
                  : adgroupTargeting.geoTargeting[getLocationType(adgroupTargeting.geoTargeting)]
              }
              booleanColor={
                editionModeContext === EditionModes.Edition
                  ? formik.values.targeting.geoExclusive
                  : adgroupTargeting.geoTargeting.geoExclusive ?? false
              }
              countryCode={countryCode}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}
